<template>
  <div class="container bp-workflow">
    <div class="content">
      <div>
        <h3 class="cs-textstyle-page-title">Simplify your workflow</h3>
        <p class="cs-textstyle-item-heading">
          Bucket+ includes over 50 plugins to automate every step of your data
          lifecylce.
        </p>
      </div>

      <div class="bp-workflow__steps">
        <cs-card v-for="step of steps" :key="step.title">
          <div slot="body">
            <img :src="step.icon" alt="" />
            <div class="cs-textstyle-section-heading">{{ step.title }}</div>
            <div class="cs-textstyle-body-paragraph">{{ step.text }}</div>
          </div>
        </cs-card>
      </div>
    </div>
  </div>
</template>
<script>
const iconImport = require('@/assets/icon_import.png');
const iconTransform = require('@/assets/icon_transform.png');
const iconUnderstand = require('@/assets/icon_understand.png');
const iconDeliver = require('@/assets/icon_deliver.png');

export default {
  data() {
    return {
      steps: [
        {
          title: 'Import',
          text: 'Use our Accelerated Upload, Sync plugin, Crawl plugin, or Torrent plugins to get data into your bucket.',
          icon: iconImport,
        },
        {
          title: 'Transform',
          text: 'Use our Convert, Transcode, and Format plugins to transform data in your bucket.',
          icon: iconTransform,
        },
        {
          title: 'Understand',
          text: 'Use our Subtitles, Recognition, and Classification plugins to understand data in your bucket.',
          icon: iconUnderstand,
        },
        {
          title: 'Deliver',
          text: 'Use our CDN and Torrent plugins to deliver data from your bucket.',
          icon: iconDeliver,
        },
      ],
    };
  },
};
</script>

<style>
.bp-workflow .content > div:first-child {
  text-align: center;
  margin-bottom: 20px;
}
.bp-workflow .content > div:first-child p {
  margin-top: 10px;
}
.bp-workflow__steps {
  display: flex;
  gap: 10px;
}
.bp-workflow__steps .cs-card {
  width: 260px;
  position: relative;
  overflow: inherit !important;
}
.bp-workflow__steps .cs-card .cs-textstyle-section-heading {
  margin-top: 10px;
  margin-bottom: 10px;
}
.bp-workflow__steps .cs-card:not(:last-child):after {
  content: url(../assets/cs-icons-next-arrow.svg);
  color: #c7cff8;
  position: absolute;
  top: 45%;
  right: 0;
  font-size: xxx-large;
  margin-right: -43px;
}
@media (max-width: 992px) {
  .bp-workflow__steps {
    justify-content: center;
    flex-wrap: wrap;
  }
}
</style>
