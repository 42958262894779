<template>
  <div class="container numbers__background">
    <img
      class="numbers__wavy-lines"
      alt="background image with wavy lines"
      :src="wavyLinesImage"
    />
    <div class="content">
      <div
        class="numbers__item"
        v-for="number of numbers"
        :key="number.label"
      >
        <div class="numbers__item-top">{{ number.number }}</div>
        <div class="numbers__item-bottom">{{ number.label }}</div>
      </div>
    </div>
  </div>
</template>
<script>
const wavyLinesImage = require('@/assets/wavy_lines.png');

export default {
  data() {
    return {
      wavyLinesImage,
      numbers: [
        {
          number: 53,
          label: 'Plugins',
        },
        {
          number: 1300,
          label: 'Invocations per minute',
        },
        {
          number: '50%',
          label: 'Typical savings',
        },
      ],
    };
  },
};
</script>
<style scoped>
.numbers__background {
  background-color: #84a0ff;
  position: relative;
  margin-bottom: 40px;
  overflow: hidden;
}
.numbers__wavy-lines {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 100%;
}
.numbers__background .content {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 40px 0;
}
.numbers__item {
  color: var(--cs-gray-00);
  font-family: 'Roboto';
  font-style: normal;
  text-align: center;
}
.numbers__item-top {
  font-weight: 700;
  font-size: 38px;
  line-height: 40px;
}
.numbers__item-bottom {
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  margin-top: 8px;
}
</style>
