<template>
    <div class="container">
      <!--EXCEPTION - CANNOT USE CONTENT-->
      <div class="row">
        <div class="col first">
          <div class="col-content">
            <h3 class="cs-textstyle-page-title">Startups / Non-Profits</h3>
            <p class="cs-textstyle-informative-paragraph">Join our Startup or Non-Profit program to get up to $1,000 in Bucket+ credits.</p>
          </div>
        </div>
        <div class="col">
          <div class="col-content">
            <h3 class="cs-textstyle-page-title">Testimonial</h3>
            <img :src="Quote"/>
            <p class="cs-textstyle-informative-paragraph">Bucket+ enables our business to process billions of images, videos, and text files cheaper and faster than ever before.”</p>
            <p class="cs-textstyle-informative-paragraph-bold">Richard Jones</p>
            <p class="cs-textstyle-informative-paragraph">CTO @ Contrend</p>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import Quote from '@/assets/quote_contrend.png';

export default {
  data() {
    return {
      Quote,
    };
  },
};
</script>

<style scoped>
.container{
  margin: 0px;
  padding: 0;
}
.col-content {
  max-width: 80%;
}
.row{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.col{
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--sections-padding-y);
  flex-direction: column;
  gap: 30px;
  text-align: center;
}
.first{
  background: #142572;
  color: var(--cs-gray-00)
}
@media (min-width: 768px) {
  .row{
    flex-direction: row;
  }
  .col{
    padding: 50px;
    padding: 100px;
  }
  .col-content {
    max-width: 400px;
  }
}
</style>
