<template>
  <div class="home">
    <app-header />
    <app-jumbotron />
    <app-summary />
    <app-numbers />
    <app-workflow />
    <app-clouds />
    <app-benefits />
    <app-plugins />
    <app-startup-quote />
    <app-call-to-action />
    <app-footer />
  </div>
</template>

<script>
import AppBenefits from '@/components/Benefits.vue';
import AppCallToAction from '@/components/CallToAction.vue';
import AppClouds from '@/components/Clouds.vue';
import AppFooter from '@/components/Footer.vue';
import AppHeader from '@/components/Header.vue';
import AppJumbotron from '@/components/Jumbotron.vue';
import AppPlugins from '@/components/Plugins.vue';
import AppStartupQuote from '@/components/StartupQuote.vue';
import AppSummary from '@/components/Summary.vue';
import AppWorkflow from '@/components/Workflow.vue';
import AppNumbers from '@/components/Numbers.vue';

export default {
  name: 'Home',
  components: {
    AppBenefits,
    AppCallToAction,
    AppClouds,
    AppFooter,
    AppHeader,
    AppJumbotron,
    AppPlugins,
    AppStartupQuote,
    AppSummary,
    AppWorkflow,
    AppNumbers,
  },
};
</script>
