<template>
  <div class="container clouds__background">
    <div class="content">
      <h3 class="cs-textstyle-page-title">Works with everything</h3>
      <p class="cs-textstyle-item-heading">
        Bucket+ works with all cloud storage providers including:
      </p>
      <div class="clouds__array">
        <a
          class="clouds__item"
          v-for="cloud of clouds"
          :key="cloud.id"
          :href="cloud.link"
        >
          <img
            :src="cloud.image"
            :alt="`logo for ${cloud.id} cloud services`"
          />
        </a>
      </div>
    </div>
  </div>
</template>
<script>
const awsImage = require('@/assets/cloud_aws.png');
const googleImage = require('@/assets/cloud_google.png');
const backblazeImage = require('@/assets/cloud_backblaze.png');
const cloudflareImage = require('@/assets/cloud_cloudflare.png');
const wasabiImage = require('@/assets/cloud_wasabi.png');
const azureImage = require('@/assets/cloud_azure.png');
const seagateImage = require('@/assets/cloud_seagate.png');
const minioImage = require('@/assets/cloud_minio.png');

export default {
  data() {
    return {
      clouds: [
        { id: 'aws', link: 'https://aws.amazon.com/', image: awsImage },
        { id: 'google', link: 'https://cloud.google.com/', image: googleImage },
        {
          id: 'backblaze',
          link: 'https://www.backblaze.com/b2/cloud-storage.html',
          image: backblazeImage,
        },
        {
          id: 'cloudflare',
          link: 'https://www.cloudflare.com/',
          image: cloudflareImage,
        },
        { id: 'wasabi', link: 'https://wasabi.com/', image: wasabiImage },
        {
          id: 'azure',
          link: 'https://azure.microsoft.com/',
          image: azureImage,
        },
        {
          id: 'seagate',
          link: 'https://www.seagate.com/as/en/products/cloud/lyve-mass-storage-platform/',
          image: seagateImage,
        },
        { id: 'minio', link: 'https://min.io/', image: minioImage },
      ],
    };
  },
};
</script>
<style scoped>
.clouds__background {
  background-color: #f8f9fe;
}
.content {
  text-align: center;
}
.content h3 {
  color: var(--cs-gray-07);
}
.content p {
  color: var(--cs-gray-06);
  margin-top: 10px;
  margin-bottom: 48px;
}
.clouds__array {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 37px;
}
.clouds__item {
}
</style>
