<template>
  <div class="container">
    <div class="content">
        <h3 class="title cs-textstyle-page-title">Featured Plugins</h3>
        <div class="row">
          <div class="items">
          <div v-for="plugin of plugins" :key="plugin.title" class="item-row">
            <div class="img-background">
              <img :src="plugin.img" class="plugin-img"/></div>
            <div class="paragraph-text">
              <div class="cs-textstyle-section-heading">{{ plugin.title }}</div>
              <div class="cs-textstyle-paragraph">{{ plugin.text }}</div>
            </div>
          </div>
          </div>
          <div class="screenshot">
            <img :src="ss" class="screenshot__img"/>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import recognition from '@/assets/icons8-image (1) 1.svg';
import cdn from '@/assets/icons8-networking-manager (2) 1.svg';
import cc from '@/assets/cs-icons-caption-filled.svg';
import ss from '@/assets/screenshot.png';

export default {
  data() {
    return {
      ss,
      plugins: [
        {
          title: 'Image Recognition',
          text: 'The Image Recognition plugin uses AI to identify people and objects in an image.',
          img: recognition,
        },
        {
          title: 'CDN',
          text: 'The CDN plugin adds a full-featured CDN to the bucket.',
          img: cdn,
        },
        {
          title: 'Subtitles',
          text: 'The Subtitles plugin uses AI to generate subtitles for a given audio / video file.',
          img: cc,
        },
      ],
    };
  },
};
</script>

<style scoped>
.item-row{
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  align-items: flex-start;
  gap: 20px
}
.img-background{
  background: #223AAC;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
}
.img-background img {
  width: 24px;
  height: 24px;
}

.row{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.items{
  display: flex;
  flex-direction: column;
  width: 50%;
  padding-right: 50px;
}
.screenshot{
  width: 50%
}
.screenshot__img{
  width: 100%
}
.container{
 background: #F3F6FF;
}
.title{
  text-align: center;
  margin-bottom: 40px;
}
.paragraph-text{
  display: flex;
  flex-direction: column;
  gap: 10px
}
@media (max-width: 576px) {
  .row{
    flex-direction: column-reverse;
  }
  .items{
    width: 100%
  }
  .screenshot{
    width: 100%;
    margin-bottom: 20px;
  }
}
</style>
