<template>
  <div class="container">
    <div class="content">
      <div class="row">
        <div class="col call-to-action-text cs-textstyle-page-subtitle">
          Give your buckets superpowers!
        </div>
        <div>
          <app-request-access-link>
            <cs-button
              class="white-call-to-action"
              fill="outline"
              corners="rounded"
              >Request Access</cs-button
            >
          </app-request-access-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AppRequestAccessLink from '@/components/RequestAccessLink.vue';

export default {
  components: {
    AppRequestAccessLink,
  },
};
</script>
<style scoped>
.container {
  background-color: var(--cs-primary-base);
}
.row {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}
.white-call-to-action.cs-button.cs-button--outline {
  background-color: white;
}
.white-call-to-action.cs-button.cs-button--outline:hover {
  background-color: white;
}
.call-to-action-text {
  color: white;
}
@media (min-width: 576px) {
  .row {
    flex-direction: row;
    gap: initial;
  }
  .call-to-action-text {
    max-width: 300px;
  }
}
</style>
